@layer reset {
  *, :before, :after {
    box-sizing: border-box;
  }

  html {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    font-family: system-ui, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
    line-height: 1.15;
  }

  body {
    margin: 0;
  }

  b, strong {
    font-weight: bolder;
  }

  code, kbd, samp, pre {
    font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  sub, sup {
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0;
    position: relative;
  }

  sub {
    bottom: -.25em;
  }

  sup {
    top: -.5em;
  }

  table {
    border-color: currentColor;
  }

  button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
  }

  button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
  }

  legend {
    padding: 0;
  }

  progress {
    vertical-align: baseline;
  }

  ::-webkit-inner-spin-button {
    height: auto;
  }

  ::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  summary {
    display: list-item;
  }
}

@layer base {
  :root {
    --text-xxs: .6rem;
    --text-xs: .75rem;
    --text-sm: .875rem;
    --text-base: 1rem;
    --text-lg: 1.125rem;
    --text-xl: 1.25rem;
    --text-2xl: 1.5rem;
    --text-3xl: 1.875rem;
    --text-4xl: 2.25rem;
    --text-5xl: 3rem;
    --text-6xl: 3.75rem;
    --text-7xl: 4.5rem;
    --text-8xl: 6rem;
    --text-9xl: 8rem;
  }

  ul[class], li[class] {
    padding: 0;
    list-style: none;
  }

  html {
    overscroll-behavior: none;
  }

  body > div[style] {
    opacity: .2;
    bottom: 5rem !important;
  }
}

@layer theme;

@layer layout {
  .layout {
    grid-template-rows: 1fr 4rem;
    grid-auto-flow: row;
    height: 100dvh;
    display: grid;
  }

  .content {
    max-width: 100%;
    overflow: hidden;
  }
}

@layer components {
  .top-bar {
    color: #fff;
    background: #000;
    justify-content: space-between;
    height: 3rem;
    margin: 0;
    padding: 0 .5rem;
    display: flex;

    & > li {
      letter-spacing: -.01em;
      font-weight: 700;
      font-size: var(--text-base);
      align-items: center;
      display: flex;

      & > a {
        color: inherit;
        font-weight: 500;
        font-size: var(--text-xs);
        align-items: center;
        gap: .25rem;
        text-decoration: none;
        display: flex;
      }
    }
  }

  .menu {
    background: #d3d3d3;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    padding: 0 .5rem;
    display: flex;

    & > li {
      display: flex;

      & > a {
        font-size: var(--text-xxs);
        color: inherit;
        flex-direction: column;
        align-items: center;
        gap: .25rem;
        text-decoration: none;
        display: flex;
      }
    }
  }

  .login-form {
    flex-direction: column;
    justify-content: end;
    gap: 1rem;
    height: 100dvh;
    padding: 1rem;
    display: flex;

    & input {
      height: 3rem;
      padding: 1rem;
    }

    & button {
      height: 3rem;
    }
  }

  .narratives {
    grid-template-columns: max-content max-content 1fr;
    padding-inline: .5rem;
    display: grid;
    overflow-x: scroll;

    & li {
      grid-column: 1 / -1;
      grid-template-columns: subgrid;
      font-size: var(--text-xs);
      align-items: center;
      column-gap: 1em;
      min-height: 2lh;
      padding-block: .25rem;
      display: grid;

      &:not(:last-child) {
        border-bottom: 1px solid #d3d3d3;
      }
    }
  }

  .progressive, .conservative {
    border-radius: 100%;
    width: 1em;
    height: 1em;
  }

  .progressive {
    background: #8fbc8f;
  }

  .conservative {
    background-color: #add8e6;
  }

  .topics {
    gap: .25rem;
    display: flex;
  }

  .topic {
    background: #add8e6;
    border-radius: 999px;
    padding: .4em .8em;
    display: inline-flex;
  }
}
/*# sourceMappingURL=index.38d08f5d.css.map */
