@layer reset, base, theme, layout, components;

@import "./modern-normalize.css" layer(reset);

@layer base {
  :root {
    --text-xxs: 0.6rem; /* 10px */
    --text-xs: 0.75rem; /* 12px */
    --text-sm: 0.875rem; /* 14px */
    --text-base: 1rem; /* 16px */
    --text-lg: 1.125rem; /* 18px */
    --text-xl: 1.25rem; /* 20px */
    --text-2xl: 1.5rem; /* 24px */
    --text-3xl: 1.875rem; /* 30px */
    --text-4xl: 2.25rem; /* 36px */
    --text-5xl: 3rem; /* 48px */
    --text-6xl: 3.75rem; /* 60px */
    --text-7xl: 4.5rem; /* 72px */
    --text-8xl: 6rem; /* 96px */
    --text-9xl: 8rem; /* 128px */
  }

  ul[class],
  li[class] {
    list-style: none;
    padding: 0;
  }

  html {
    overscroll-behavior: none;
  }

  body > div[style] {
    bottom: 5rem !important;
    opacity: 0.2;
  }
}

@layer layout {
  .layout {
    display: grid;
    grid-template-rows: 1fr 4rem;
    grid-auto-flow: row;
    height: 100dvh;
  }

  .content {
    max-width: 100%;
    overflow: hidden;
  }
}

@layer components {
  .top-bar {
    display: flex;
    padding: 0 0.5rem;
    margin: 0;
    height: 3rem;
    justify-content: space-between;
    color: white;
    background: black;

    > li {
      display: flex;
      align-items: center;
      font-weight: 700;
      letter-spacing: -0.01em;
      font-size: var(--text-base);

      > a {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        text-decoration: none;
        color: inherit;
        font-weight: 500;
        font-size: var(--text-xs);
      }
    }
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 0.5rem;
    margin: 0;
    background: lightgray;

    > li {
      display: flex;
      > a {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        align-items: center;
        font-size: var(--text-xxs);
        color: inherit;
        text-decoration: none;
      }
    }
  }

  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 1rem;
    padding: 1rem;
    height: 100dvh;

    input {
      height: 3rem;
      padding: 1rem;
    }
    button {
      height: 3rem;
    }
  }

  .narratives {
    overflow-x: scroll;
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    padding-inline: 0.5rem;

    li {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: subgrid;
      column-gap: 1em;
      align-items: center;
      min-height: 2lh;
      padding-block: 0.25rem;
      font-size: var(--text-xs);

      &:not(:last-child) {
        border-bottom: 1px solid lightgray;
      }
    }
  }

  .progressive,
  .conservative {
    border-radius: 100%;
    width: 1em;
    height: 1em;
  }
  .progressive {
    background: darkseagreen;
  }
  .conservative {
    background-color: lightblue;
  }

  .topics {
    display: flex;
    gap: 0.25rem;
  }

  .topic {
    display: inline-flex;
    padding: 0.4em 0.8em;
    border-radius: 999px;
    background: lightblue;
  }
}
